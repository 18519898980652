import { StatesModule } from '@uirouter/angular';

import { ViewportComponent } from './viewport/viewport.component';
import { ScreenSharingComponent } from './components/screen-sharing/screen-sharing.component';
import { ModeratorJoinComponent } from './components/moderator-join/moderator-join.component';
import { CanAccessApps } from '../ajs-upgraded-providers';
import { ModeratorRoomComponent } from './components/moderator-room/moderator-room.component';

export const routes: StatesModule = {
  states: [

    {
      name: 'apps.screen-sharing',
      abstract: true,
      component: ViewportComponent
    },
    {
      name: 'apps.screen-sharing.home',
      url: '/join?rvshare&invalidCode&connectionError',
      component: ScreenSharingComponent
    },
    {
      name: 'apps.screen-sharing.moderator-join',
      url: '/screen-sharing/join',
      component: ModeratorJoinComponent,
      resolve: [{
        token: 'moderatorJoin',
        deps: [CanAccessApps],
        resolveFn: (canAccessApps) => {
          return canAccessApps();
        }
      }]
    },
    {
      name: 'apps.screen-sharing.moderator-room',
      url: '/screen-sharing/room/:displayId',
      component: ModeratorRoomComponent,
      resolve: [{
        token: 'moderatorRoom',
        deps: [CanAccessApps],
        resolveFn: (canAccessApps) => {
          return canAccessApps();
        }
      }]
    }
  ]
};

import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    iconRegistry.setDefaultFontSetClass('fa');
  }

  registerIcons() {
    this.iconRegistry.addSvgIconLiteral('chevron-right', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 18L15 12L9 6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('close', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <path d="M35.7474305,30.4420732 C35.6298881,30.3248227 35.5638285,30.1656187 35.5638285,29.9995916 C35.5638285,29.8335644 35.6298881,29.6743604 35.7474305,29.5571099 L58.903005,6.40307058 C60.3671281,4.9368154 60.3654493,2.56122496 58.8992552,1.09704078 C57.4330612,-0.367143388 55.0575697,-0.36546455 53.5934465,1.10079057 L30.4403718,24.2448303 C30.3231262,24.3623776 30.1639289,24.4284399 29.9979086,24.4284399 C29.8318883,24.4284399 29.672691,24.3623776 29.5554454,24.2448303 L6.40237068,1.10079057 C4.93824748,-0.364083948 2.56387516,-0.364643569 1.09906167,1.09954062 C-0.36575182,2.56372482 -0.366311417,4.93819606 1.09781178,6.40307058 L24.2483867,29.5571099 C24.365929,29.6743604 24.4319886,29.8335644 24.4319886,29.9995916 C24.4319886,30.1656187 24.365929,30.3248227 24.2483867,30.4420732 L1.09781178,53.5986124 C0.150690139,54.5462201 -0.218964737,55.9271501 0.128092562,57.2212195 C0.475149861,58.5152889 1.48619308,59.5258978 2.78037201,59.8723595 C4.07455095,60.2188213 5.45524904,59.8485001 6.40237068,58.9008924 L29.5554454,35.7443532 C29.672691,35.626806 29.8318883,35.5607436 29.9979086,35.5607436 C30.1639289,35.5607436 30.3231262,35.626806 30.4403718,35.7443532 L53.5934465,58.9008924 C55.0575697,60.3657669 57.431942,60.3663265 58.8967555,58.9021423 C60.3615689,57.4379582 60.3621285,55.063487 58.8980054,53.5986124 L35.7474305,30.4420732 Z" />
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('exit', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.83337 2.66671C3.65656 2.66671 3.48699 2.73695 3.36197 2.86197C3.23695 2.98699 3.16671 3.15656 3.16671 3.33337V12.6667C3.16671 12.8435 3.23695 13.0131 3.36197 13.1381C3.48699 13.2631 3.65656 13.3334 3.83337 13.3334H6.50004C6.86823 13.3334 7.16671 13.6319 7.16671 14C7.16671 14.3682 6.86823 14.6667 6.50004 14.6667H3.83337C3.30294 14.6667 2.79423 14.456 2.41916 14.0809C2.04409 13.7058 1.83337 13.1971 1.83337 12.6667V3.33337C1.83337 2.80294 2.04409 2.29423 2.41916 1.91916C2.79423 1.54409 3.30294 1.33337 3.83337 1.33337H6.50004C6.86823 1.33337 7.16671 1.63185 7.16671 2.00004C7.16671 2.36823 6.86823 2.66671 6.50004 2.66671H3.83337Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6953 4.19526C10.9556 3.93491 11.3777 3.93491 11.6381 4.19526L14.9714 7.5286C15.2318 7.78894 15.2318 8.21106 14.9714 8.4714L11.6381 11.8047C11.3777 12.0651 10.9556 12.0651 10.6953 11.8047C10.4349 11.5444 10.4349 11.1223 10.6953 10.8619L13.5572 8L10.6953 5.13807C10.4349 4.87772 10.4349 4.45561 10.6953 4.19526Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83337 8.00004C5.83337 7.63185 6.13185 7.33337 6.50004 7.33337H14.5C14.8682 7.33337 15.1667 7.63185 15.1667 8.00004C15.1667 8.36823 14.8682 8.66671 14.5 8.66671H6.50004C6.13185 8.66671 5.83337 8.36823 5.83337 8.00004Z" />
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('pause', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00004 2.66663H4.33337V13.3333H7.00004V2.66663Z" />
        <path d="M12.3333 2.66663H9.66663V13.3333H12.3333V2.66663Z" />
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('play', this.sanitizer.bypassSecurityTrustHtml(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 18 18" id="Button-Play--Streamline-Core.svg" height="20" width="20">
        <desc>Button Play Streamline Icon: https://streamlinehq.com</desc>
        <g id="button-play--button-television-buttons-movies-play-tv-video-controls">
          <path id="Union" fill-rule="evenodd" d="M3.058205714285714 0.021902857142857144c-0.3380114285714286 0 -0.6703657142857143 0.08615714285714285 -0.9657028571428571 0.2502262857142857 -0.30073142857142854 0.1533017142857143 -0.5545714285714286 0.3850239999999999 -0.7345942857142856 0.6707771428571427 -0.18261714285714284 0.28985942857142855 -0.2822902857142857 0.6242365714285714 -0.2881931428571428 0.9667737142857142l-0.0000857142857142857 0V14.1024H1.0695451428571427l0.00017028571428571426 0.009828571428571428c0.005902857142857143 0.3425142857142857 0.105576 0.6769142857142857 0.2881931428571428 0.9667428571428571 0.18002285714285712 0.2858285714285714 0.43386285714285716 0.5174857142857142 0.7345942857142856 0.6708571428571428 0.2953371428571428 0.16399999999999998 0.6276914285714286 0.25017142857142854 0.9657028571428571 0.25017142857142854 0.3428 0 0.679782857142857 -0.08857142857142856 0.97824 -0.25725714285714285 0.006571428571428571 -0.003657142857142857 0.013062857142857142 -0.007542857142857143 0.01947428571428571 -0.011542857142857141l9.862594285714286 -6.099942857142857c0.29908571428571423 -0.15073142857142857 0.5515428571428571 -0.38036571428571425 0.7300571428571428 -0.6643428571428571 0.1841142857142857 -0.2932457142857143 0.2819428571428571 -0.6325142857142857 0.2819428571428571 -0.978822857142857 0 -0.34629714285714286 -0.09782857142857142 -0.6855771428571428 -0.2819428571428571 -0.9788114285714286 -0.17862857142857141 -0.2842742857142857 -0.4315428571428571 -0.5141028571428571 -0.7309714285714285 -0.6648228571428572L4.054297142857142 0.2896742857142857c-0.005885714285714286 -0.0036114285714285714 -0.011839999999999998 -0.007116571428571428 -0.01785142857142857 -0.010513142857142858 -0.2984571428571428 -0.16863862857142856 -0.63544 -0.2572582857142857 -0.97824 -0.2572582857142857Z" clip-rule="evenodd" stroke-width="1"></path>
        </g>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('stop-circle', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3333 8.00004C14.3333 11.6819 11.3486 14.6667 7.66667 14.6667C3.98477 14.6667 1 11.6819 1 8.00004C1 4.31814 3.98477 1.33337 7.66667 1.33337C11.3486 1.33337 14.3333 4.31814 14.3333 8.00004ZM5.66667 6.00004H9.66667V10H5.66667V6.00004Z" />
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('user', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.33317C8.61929 3.33317 7.5 4.45246 7.5 5.83317C7.5 7.21388 8.61929 8.33317 10 8.33317C11.3807 8.33317 12.5 7.21388 12.5 5.83317C12.5 4.45246 11.3807 3.33317 10 3.33317ZM5.83333 5.83317C5.83333 3.53198 7.69881 1.6665 10 1.6665C12.3012 1.6665 14.1667 3.53198 14.1667 5.83317C14.1667 8.13436 12.3012 9.99984 10 9.99984C7.69881 9.99984 5.83333 8.13436 5.83333 5.83317ZM3.72039 12.8869C4.50179 12.1055 5.5616 11.6665 6.66667 11.6665H13.3333C14.4384 11.6665 15.4982 12.1055 16.2796 12.8869C17.061 13.6683 17.5 14.7281 17.5 15.8332V17.4998C17.5 17.9601 17.1269 18.3332 16.6667 18.3332C16.2064 18.3332 15.8333 17.9601 15.8333 17.4998V15.8332C15.8333 15.1701 15.5699 14.5342 15.1011 14.0654C14.6323 13.5966 13.9964 13.3332 13.3333 13.3332H6.66667C6.00363 13.3332 5.36774 13.5966 4.8989 14.0654C4.43006 14.5342 4.16667 15.1701 4.16667 15.8332V17.4998C4.16667 17.9601 3.79357 18.3332 3.33333 18.3332C2.8731 18.3332 2.5 17.9601 2.5 17.4998V15.8332C2.5 14.7281 2.93899 13.6683 3.72039 12.8869Z"/>
      </svg>
    `));
  }
}
